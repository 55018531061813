import { useState } from "react";
import api from "../../helper/api";


const config = require('../../config/config');
const unitmin = config.unit.min;
const unitmax = config.unit.max;


const DeliveryForm = ( { onDeliverySubmit }) => {
    const [deliveryData, setDeliveryData] = useState({        
        unit: '',
        courier: '',
        packagetype: '',
        tracking: '',
        location: '',
        conciergeId: ''
    });
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false); 
    const [successClass, setSuccessClass] = useState('success-message-hidden');
   
  
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (isSuccess) {
            resetForm();
        }
        setDeliveryData((prevData) => ({ ...prevData, [name]: value }));

        // Validate the field when its value changes
        const updatedErrors = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, ...updatedErrors }));

        // Remove the error message for the specific field being changed
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];
            return newErrors;
        });
    };

    const resetForm = () => {
        setDeliveryData({ unit: '', courier: '', packagetype: '', tracking: '', location: '', conciergeId: '' });
        setIsSuccess(false);
    };

    const validateField = (fieldName, fieldValue) => {
        let error = {};
    
        switch (fieldName) {
            case 'unit':
                if (!fieldValue) {
                    error.unit = 'Unit is required';
                } else {
                    const num = Number(fieldValue);
                    if (isNaN(num) || num < unitmin || num > unitmax) {
                      if (!fieldValue.match(/^TH[1-3]$/)) {
                        error.unit = `Invalid unit number`;
                      }
                    }
                }
                break;
            case 'courier':
                if (!fieldValue) {
                    error.courier = "Courier is required";
                }
                break;
            case 'packagetype':
                if (!fieldValue) {
                    error.packagetype = "Package type is required";
                }
                break;
            default:
                break;
        }
        
        return error;
    };
    
    const validateData = () => {
        let errors = {};
    
        errors = { ...errors, ...validateField('unit', deliveryData.unit) };
        errors = { ...errors, ...validateField('courier', deliveryData.courier) };
        errors = { ...errors, ...validateField('packagetype', deliveryData.packagetype) };
    
        return errors;
    };

    const handleSave = async () => {
        const errors = validateData();
        if (Object.keys(errors).length) {
            setErrors(errors);
            // there are errors, do not continue saving
            return;
        }

        setErrors({});
        console.log(deliveryData);

        try {
            // make a POST request to the API endpoint
            const response = await api.post('/deliveries', deliveryData);
            
            // Set isSuccess to true when the POST request is successful
            setIsSuccess(true); 

            // Reset form data after successful submission
            setDeliveryData({ unit: '', courier: '', packagetype: '', tracking: '', location: '', conciergeId: '' }); // Reset form data after successful submission            
            
            // Call the onDeliverySubmit function passed as a prop from AdminDelivery.jsx
            onDeliverySubmit(response.data.packageId); 

            // Set the success message class to visible
            setSuccessClass('success-message-visible');

            // Remove the success message after 3 seconds
            setTimeout(() => {
                setIsSuccess(false);
            }, 3000);
            
        }
        catch (error) {

            // If the error is a 404, then the unit does not exist in the database
            if (error.response && error.response.status === 404) {
                console.error('Delivery failed: ', error);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  general: 'Cannot find residents in the database to notify for this unit.',
                }));
            }

            // If the error is a something else, then it is an unexpected error
            else {
                console.error('Delivery failed: ', error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    general: 'An unexpected error occurred. Please try again later.',
                }));
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave();
    };

    return (
        <div className='admin-component-tile'>
            <form onSubmit={handleSubmit}>
                <h1>Delivery Entry</h1>
                <div className="form-grid">
                    <div className="form-group">
                        <p>Unit</p>
                        <input name="unit" value={deliveryData.unit} onChange={handleChange} aria-label="Unit" />
                        <div style={{ color: "red" }}>{errors.unit}</div>
                    </div>
                    <div className="form-group">    
                        <p>Courier</p>
                        <select
                            name="courier"
                            value={deliveryData.courier}
                            onChange={handleChange}
                        >
                            <option value="">Choose Courier</option>
                            <option value="Amazon">Amazon</option>
                            <option value="Canada Post">Canada Post</option>
                            <option value="UPS">UPS</option>
                            <option value="DHL">DHL</option>
                            <option value="Purolator">Purolator</option>
                            <option value="FedEx">FedEx</option>
                            <option value="Royal Mail">Royal Mail</option>
                            <option value="Can Par">Can Par</option>
                            <option value="Other">Other</option>
                        </select>
                        <div style={{ color: "red" }}>{errors.courier}</div>
                    </div>
                    <div className="form-group">
                        <p>Package Type</p>
                        <select
                        id="packagetype"
                        name="packagetype"
                        value={deliveryData.packagetype}
                        onChange={handleChange}
                        >
                            <option value="">Choose Package Type</option>
                            <option value="Box">Box</option>
                            <option value="Letter">Letter</option>
                            <option value="Package">Package</option>
                            <option value="Envelope">Envelope</option>
                            <option value="Food">Food</option>
                            <option value="Pharmacy">Pharmacy</option>
                            <option value="Flowers">Flowers</option>
                        </select>
                        <div style={{ color: "red" }}>{errors.packagetype}</div>
                    </div>
                </div>
                <h3>(Optional Fields)</h3>
                <div className="form-grid">
                    <div className="form-group">
                        <p>Package Tracking Number</p>
                        <input name="tracking" value={deliveryData.tracking} onChange={handleChange} aria-label="Package Tracking Number" />
                    </div>
                    <div className="form-group">
                        <p>Location</p>
                        <input name="location" value={deliveryData.location} onChange={handleChange} aria-label="Location" />
                    </div>
                    <div className="form-group">
                        <p>Concierge Id</p>
                        <input name="conciergeId" value={deliveryData.conciergeId} onChange={handleChange} aria-label="Concierge Id" />
                    </div>       
                </div>
                <div style={{ marginTop: "12px", color: "red" }}>{errors.general}</div>
                {isSuccess && (
                <div className={`success-message ${successClass}`}>
                    Delivery entry completed!  
                </div>
                )}
                <div style={{ marginTop: "12px" }}>
                    <button type="submit">Save</button>
                </div>  
            </form>
        </div>
    );
};        

export default DeliveryForm;